import { graphql, Link } from "gatsby";
import React from "react";
import { useGlobalState } from "../state/globalState";
import { ShopifyProduct } from "../types/shop";

export default function Shop(props: any) {
  const products: ShopifyProduct[] = props.data.allShopifyProduct.edges.map(
    (e: any) => e.node as ShopifyProduct
  );

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  return (
    <>
      <div className="mb-16" />
      <div className="px-4 py-16 container-5xl">
        <div className="container-5xl">
          <h1 className="text-4xl font-bold">PLAN3T Merch</h1>
        </div>
        <div className="grid gap-8 py-16 container-5xl md:grid-cols-2">
          {products.map((p) => (
            <Link
              key={p.shopifyId}
              to={`/shop/${p.handle}`}
              className="flex flex-col overflow-hidden bg-white shadow-lg cursor-pointer rounded-xl hover:shadow-xl"
            >
              {p.images.length > 0 && (
                <img
                  src={p.images[0].originalSrc}
                  alt={p.title}
                  className="object-cover h-64"
                />
              )}
              <div className="p-6 space-y-4">
                <h3 className="text-2xl font-bold font-headline">{p.title}</h3>
                <p className="text-base text-gray-700">
                  {`${p.description.substring(0, 200)}...`}
                </p>
                <div>
                  <span className="button-blue-filled">
                    {parseFloat(p.priceRangeV2.minVariantPrice.amount).toFixed(
                      2
                    )}{" "}
                    €
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ShopQuery($language: String!) {
    allShopifyProduct(filter: { vendor: { eq: "Plan3t" } }) {
      edges {
        node {
          title
          shopifyId
          handle
          description
          productType
          vendor
          images {
            originalSrc
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
